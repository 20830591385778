var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-activity-settings"},[_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'value',
        'prefix': ("integration_" + _vm.type + "_"),
        'validation': 'required',
      }
    }}}),_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'lower_limit',
        'prefix': "integration_",
        'validation': 'required',
        'max': _vm.model.upper_limit
      }
    }}}),_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'upper_limit',
        'prefix': "integration_",
        'validation': 'required',
        'min': _vm.model.lower_limit
      }
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'reason',
        'prefix': 'integration_',
        'settingWrapper': {
          customiseValue: '',
          hasFieldButton: true,
          disabledValue: '',
          buttonTitle: _vm.$t('customise')
        },
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }